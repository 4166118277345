import styled from 'styled-components';


export const Container = styled.div`
    a { color: #70AEA9; }
    width: 100%;
    padding: 20px;
    text-align: center;
`

