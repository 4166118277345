const data = [
    {text: "What do I need to know?", type: "blog-posts" },
    {text: "What do I need to try?", type: "blog-posts" },
    {text: "What do I need to accept?", type: "blog-posts" },
    {text: "What do I need to do?", type: "blog-posts" },
    {text: "What should I do next?", type: "blog-posts" },
    {text: "What purpose do you assign to your life?", type: "blog-posts" },
    {text: "What object do you treasure most?", type: "blog-posts" },
    {text: "What do you fear the most?", type: "blog-posts" },
    {text: "What do you love the most?", type: "blog-posts" },
    {text: "How could you be nicer to yourself?", type: "blog-posts" },
    {text: "What do you wish you could spend less time doing?", type: "blog-posts" },
    {text: "What do you wish you could spend more time doing?", type: "blog-posts" },
    {text: "What song always makes you cry? Why?", type: "blog-posts" },
    {text: "What would your ideal world look like?", type: "blog-posts" },
    {text: "What is the best day you remember?", type: "blog-posts" },
    {text: "If you could change one thing about the world, what would it be? Why?", type: "blog-posts" },
    {text: "Is it more comforting to believe or not to believe in an afterlife?", type: "blog-posts" },
    {text: "If you could change one decision you’ve made in your life, what would it be?", type: "blog-posts" },
    {text: "If someone offered you three wishes, what would you wish for?", type: "blog-posts" },
    {text: "Who is an important person in your life?", type: "blog-posts" },
    {text: "Who was your most important role model?", type: "blog-posts" },
    {text: "If you taught a class on life, what would be the textbook?", type: "blog-posts" },
    {text: "Do you think it’s more important to be practical or imaginative?", type: "blog-posts" },
    {text: "What is the best number and why?", type: "blog-posts" },
    {text: "What do you wish other people knew about you?", type: "blog-posts" },
    {text: "What do you wish you knew about someone else?", type: "blog-posts" },
    {text: "What do we have a responsibility to do?", type: "blog-posts" },
    {text: "How would you define morality?", type: "blog-posts" },
    {text: "What do you most want to do in your life?", type: "blog-posts" },
    {text: "What have you built?", type: "blog-posts" },
    {text: "What was a time when you needed support from someone else?", type: "blog-posts" },
    {text: "What do you think the future will be like?", type: "blog-posts" },
    {text: "When do you feel most confident?", type: "blog-posts" },
    {text: "What makes you smile?", type: "blog-posts" },
    {text: "Write about what it was like when you first met your best friend.", type: "blog-posts" },
    {text: "Write about the best day of your life.", type: "blog-posts" },
    {text: "Write about something you regret.", type: "blog-posts" },
    {text: "Write a letter to yourself 10 years ago", type: "blog-posts" },
    {text: "Write about what you loved to wear when you were 15.", type: "blog-posts" },
    {text: "Write about what comes to mind when you smell a certain fragrance.", type: "blog-posts" },
    {text: "Write about the most beautiful place you’ve ever seen.", type: "blog-posts" },
    {text: "Write about the most difficult choice you made.", type: "blog-posts" },
    {text: "Write about the highlights of your adolescence.", type: "blog-posts" },
    {text: "Write about your favourite musician.", type: "blog-posts" },
    {text: "Write about how you’d like your life to look when you’re 93.", type: "blog-posts" },
    {text: "Write down all the interesting things you see on a walk around your neighbourhood.", type: "blog-posts" },
    {text: "Write about your favourite tarot card", type: "blog-posts" },
    {text: "Write about a simple decision that changed the course of your life forever.", type: "blog-posts" },
    {text: "Write totally new lyrics to one of your favourite songs.", type: "blog-posts" },
    {text: "Write whatever you would write if you knew no one would ever read it.", type: "blog-posts" },
    {text: "Write about something you never expected to see.", type: "blog-posts" },
    {text: "Write about the sense (sight, sound, smell, touch, taste) you treasure most.", type: "blog-posts" },
    {text: "Write about something you no longer believe.", type: "blog-posts" },
    {text: "Write about something you love.", type: "blog-posts" },
    {text: "A recent trip you took", type: "blog-posts" },
    {text: "A business lesson you learnt", type: "blog-posts" },
    {text: "Your current obsession", type: "blog-posts" },
    {text: "Make a mixtape!", type: "blog-posts" },
    {text: "Share some personal photos", type: "blog-posts" },
    {text: "Tell a story", type: "blog-posts" },
    {text: "Free write", type: "blog-posts" },
    {text: "Answer your reader’s questions", type: "blog-posts" },
    {text: "Interview someone you admire", type: "blog-posts" },
    {text: "Create a real life moodboard", type: "blog-posts" },
    {text: "Share your favourite outfit", type: "blog-posts" },
    {text: "Run a competition", type: "blog-posts" },
    {text: "Show us your office!", type: "blog-posts" },
    {text: "Go for a photo walk", type: "blog-posts" },
    {text: "Illustrate!", type: "blog-posts" },
    {text: "Do something scary… and then write about it!", type: "blog-posts" },
    {text: "Work on a personal project", type: "blog-posts" },
    {text: "Share a secret", type: "blog-posts" },
    {text: "Write a poem", type: "blog-posts" },
    {text: "Review your latest purchase", type: "blog-posts" },
    {text: "Ask yourself “what do I wish I’d known 5 years ago?”", type: "blog-posts" },
    {text: "Share a beauty hack", type: "blog-posts" },
    {text: "Think about your ideal reader, how can you help them?", type: "blog-posts" },
    {text: "Share what you’re thankful for", type: "blog-posts" },
    {text: "Share some goals", type: "blog-posts" },
    {text: "Start a photo a day challenge", type: "blog-posts" },
    {text: "Make a video!", type: "blog-posts" },
    {text: "Revisit and then rewrite an old, popular post", type: "blog-posts" },
    {text: "Link to your favourite bloggers", type: "blog-posts" },
    {text: "Write about overcoming blogger’s block!", type: "blog-posts" },
    {text: "Doing, Eating, Listening, Watching, Learning, Everything else.", type: "blog-posts" },
    {text: "Minimalist challenge - capsule wardrobe", type: "blog-posts" },
    {text: "minimalist challenge - rss reader", type: "blog-posts" },
    {text: "minimalist challenge - data &amp; backups", type: "blog-posts" },
    {text: "Nostalgia - What are you nostalgic for? What do you miss?", type: "blog-posts" },
    {text: "Favourite colour", type: "blog-posts" },
    {text: "Your inspiration - Who or what inspires you?", type: "blog-posts" },
    {text: "What would your perfect weekend look and feel like? ", type: "blog-posts" },
    {text: "What are you obsessed with right now", type: "blog-posts" },
    {text: "A beautiful place - What is the most beautiful place you’ve ever seen? ", type: "blog-posts" },
    {text: "Pay it forward - Do something good for someone else today", type: "blog-posts" },
    {text: "Big dream - Your ultimate goal", type: "blog-posts" },
    {text: "Favourite artist - Whose art really speaks to you? ", type: "blog-posts" },
    {text: "In the morning - What do you do every morning? ", type: "blog-posts" },
    {text: "Your bucket list - What’s on your list of things to do before you die? ", type: "blog-posts" },
    {text: "Your Favourite city ", type: "blog-posts" },
    {text: "Your best friends", type: "blog-posts" },
    {text: "What is your favourite season", type: "blog-posts" },
    {text: "Throwback Thursday", type: "blog-posts" },
    {text: "Spirituality - How do you define spirituality? what does it mean to you?", type: "blog-posts" },
    {text: "Before and after - Find a picture of yourself from ten years ago what has changed?", type: "blog-posts" },
    {text: "Self-care routine - How do you take care of yourself? ", type: "blog-posts" },
    {text: "Wonder - What fills you with a sense of wonder every time you see it?", type: "blog-posts" },
    {text: "Biggest challenge - What’s the most challenging thing you’ve ever experienced.", type: "blog-posts" },
    {text: "Beside your bed - What do you keep next to your bed? ", type: "blog-posts" },
    {text: "Advice - Give some advice", type: "blog-posts" },
    {text: "Your idol or role model - Who do you admire, and why?", type: "blog-posts" },
    {text: "Your best feature", type: "blog-posts" },
    {text: "Favourite book", type: "blog-posts" },
    {text: "Draw yourself", type: "blog-posts" },
    {text: "In your bag", type: "blog-posts" },
    {text: "No makeup selfie", type: "blog-posts" },
    {text: "Your happy place ", type: "blog-posts" },
    {text: "Favourite quote ", type: "blog-posts" },
    {text: "Your city", type: "blog-posts" },
    {text: "Inspiring friend - Who is your most inspirational friend, and why? ", type: "blog-posts" },
    {text: "Magic - How do you define magic? Show us something you think is truly enchanting. ", type: "blog-posts" },
    {text: "On your wall and why", type: "blog-posts" },
    {text: "Gratitude - What your thankful for", type: "blog-posts" },
    {text: "Bed-head - What does your hair look like in the morning?", type: "blog-posts" },
    {text: "I wish I was… - either real or made up!", type: "blog-posts" },
    {text: "Pampering - How do you take care of yourself?", type: "blog-posts" },
    {text: "I love… - What do you love about yourself today?", type: "blog-posts" },
    {text: "feminist friday - write about a woman who has had a great impact on your life", type: "blog-posts" },
    {text: "current things. (music,book,movie)", type: "blog-posts" },
    {text: "links saved", type: "blog-posts" },
    {text: "This week at work", type: "blog-posts" },










{ text: "Who made you feel good this week?", type: "journaling" },
{ text: "What was the biggest mistake you made this week?", type: "journaling" },
{ text: "What did you do this week that moved you closer to reaching your goals?", type: "journaling" },
{ text: "Is there anything you did this week that you wish you’d done differently?", type: "journaling" },
{ text: "What did you most enjoy doing this week?", type: "journaling" },
{ text: "How did you procrastinate on important tasks this week?", type: "journaling" },
{ text: "What did you learn this week?", type: "journaling" },
{ text: "What’s the funniest thing that happened to you this week?", type: "journaling" },
{ text: "What did you accomplish?", type: "journaling" },
{ text: "What went wrong?", type: "journaling" },
{ text: "What went right?", type: "journaling" },
{ text: "Represent each day of your week with a colour. Draw it in a cirle a square whichever shape you're feeling for that day.", type: "journaling" },
{ text: "Write a short story about something that happened today, even if its the most mundane thing ever", type: "journaling" },
{ text: "5 to 10 things that you’re grateful for, every day.", type: "journaling" },
{ text: "what would make today great?", type: "journaling" },
{ text: "3 amazing things that happened today", type: "journaling" },
{ text: "how could i make today better", type: "journaling" },
{ text: "what did I accomplish?", type: "journaling" },
{ text: "what did I learn?", type: "journaling" },
{ text: "what am I grateful for?", type: "journaling" },
{ text: "what am I committed to doing better tomorrow?", type: "journaling" },
{ text: "what was my favorite thing about my day?", type: "journaling" },
{ text: "what was the least valuable thing I did today?", type: "journaling" },
{ text: "what am I most excited about in my life right now?", type: "journaling" },
{ text: "what is my greatest challenge right now?", type: "journaling" },
{ text: "What makes your face light up?", type: "journaling" },
{ text: "What am I running to or from?", type: "journaling" },
{ text: "How would you describe your day in one sentence?", type: "journaling" },
{ text: "What made you smile today?", type: "journaling" },
{ text: "List all the things you’ve purchased today.", type: "journaling" },
{ text: "What was the best part of today?", type: "journaling" },
{ text: "Something you’d always like to remember from today.", type: "journaling" },
{ text: "List all the food you’ve consumed today", type: "journaling" },
{ text: "Describe your day in ten words or less", type: "journaling" },
{ text: "Your first thought this morning", type: "journaling" },
{ text: "A simple pleasure you granted yourself today", type: "journaling" },
{ text: "A tiny step you took towards your goals/dreams", type: "journaling" },
{ text: "Three things you’re thankful for right now", type: "journaling" },
{ text: "One thing you’re stressed about and two ways to help you get through it", type: "journaling" },
{ text: "Describe your morning", type: "journaling" },
{ text: "Describe your evening.", type: "journaling" },
{ text: "Approximate minutes you spent on social media versus approximate minutes spent being social. Thoughts?", type: "journaling" },
{ text: "An overheard conversation", type: "journaling" },
{ text: "Take a minute at any point in time today, and observe what’s going on around you. Write down your immediate thoughts.", type: "journaling" },
{ text: "Today’s vitals: date, day, weather, mood, breakfast, lunch, dinner, top 3 priorities", type: "journaling" },
{ text: "What happened today at 10 am?", type: "journaling" },
{ text: "What happened today at 1pm?", type: "journaling" },
{ text: "What happened today at 7pm?", type: "journaling" },
{ text: "List everything going through your mind right now", type: "journaling" },
{ text: "Time lapse: Take your logbook/journal with you today and write one observation or thought each hour within an 8-hour time frame", type: "journaling" },
{ text: "Struggle Street: What did you struggle with today? List 3 ways to help you overcome it", type: "journaling" },
{ text: "What’s inspired you today?", type: "journaling" },
{ text: "List everything you’ve read today.", type: "journaling" },
{ text: "Something new you’ve learned today.", type: "journaling" },
{ text: "Switch off technology for 2 hours. What did you do instead?", type: "journaling" },
{ text: "Spend part of your day doing something you love. Thoughts?", type: "journaling" },
{ text: "List all the places you’ve visited today.", type: "journaling" },
{ text: "How did you relax and unwind today", type: "journaling" },
{ text: "What were your top five moments of the year?", type: "journaling" },
{ text: "What are you really glad is over?", type: "journaling" },
{ text: "How are you different today than you were 365 days ago?", type: "journaling" },
{ text: "Is there anything you achieved that you forgot to celebrate?", type: "journaling" },
{ text: "What have you changed your perspective on this year?", type: "journaling" },
{ text: "Who are the people that really came through for you this year?", type: "journaling" },
{ text: "What is something you tolerated for a long time, but now you will not?", type: "journaling" },
{ text: "What old beliefs did you let go of?", type: "journaling" },
{ text: "What was one thing that you found really challenging, but can now see supported your growth?", type: "journaling" },
{ text: "If you could go back in time, what would you tell yourself this time last year?", type: "journaling" },
{ text: "List of things to be proud of", type: "journaling" },
{ text: "List of current and future goals", type: "journaling" },
{ text: "List of what you are grateful for", type: "journaling" },
{ text: "List of things that make you feel healthy - mind body & soul", type: "journaling" },
{ text: "List of essential things", type: "journaling" },
{ text: "List of people you admire and why", type: "journaling" },
{ text: "List of ways you are energized", type: "journaling" },
{ text: "List of things you want to be known for", type: "journaling" },
{ text: "Make a list of 20 small things that you enjoy doing, and make sure that you do at least one of these things every day for the next 100 days.", type: "journaling" },
{ text: "Create a list of 10 healthy, easy to fix breakfast meals.", type: "journaling" },
{ text: "Create a list of 20 healthy, easy to fix meals which can be eaten for lunch or dinner.", type: "journaling" },
{ text: "Create a list of 10 healthy, easy to fix snacks.", type: "journaling" },
{ text: "Use your lists of healthy breakfast meals, lunches, dinners, and snacks in order to plan out your meals for the week ahead of time. Do this for the next 14 weeks.", type: "journaling" },
{ text: "Waiting on (a list of things you need to keep tabs on but that don’t require any action at this time, like packages you’ve ordered but haven’t received)", type: "journaling" },
{ text: "Household maintenance tasks (like changing out those air filters and checking smoke alarm batteries once a month)", type: "journaling" },
{ text: "List of articles", type: "journaling" },
{ text: "List of software you use", type: "journaling" },
{ text: "List of people you know and info", type: "journaling" },
{ text: "List of books to read", type: "journaling" },
{ text: "List of things recommended to you", type: "journaling" },
{ text: "You may want to keep an “areas of focus” (or “areas of responsibility”) list, which could be separated into categories such as “professional,” and “personal,” as needed.", type: "journaling" },
{ text: "Dedicate a page to writing down your dreams", type: "journaling" },
{ text: "Document your favourite outfits", type: "journaling" },
{ text: "Create a spending plan (also known as a budget). Track every cent that you spend for the next 100 days to make sure that you’re sticking to your spending plan.", type: "journaling" },
{ text: "Make a list of all of the commitments and social obligations that you have in the next 100 days. Then, take out a red pen and cross out anything that does not truly bring you joy or help move you along the path to achieving your main life goals.", type: "journaling" },
{ text: "Create a housework chart - list of chores what tasks todo and when", type: "journaling" },
{ text: "Blurb about you yourself your skills - for portfolio/quickly sending to people", type: "journaling" },
{ text: "Things you frequently reference", type: "journaling" },
{ text: "Things that inspire me", type: "journaling" },
{ text: "Nice things people say about me", type: "journaling" },
{ text: "Moments, is for recording any special moments I’d like to remember later. I tag them accordingly based on what type of moment they might be: conversations, dreams, something I feel the need to vent about. This journal gives me an outlet for my thoughts other than my boyfriend or best friend who I’m sure both appreciate the decrease in the number of rants I share.", type: "journaling" },
{ text: "Goals journal where I store my yearly list of goals I’d like to work towards. I used to keep text files for each year, but it’s nice to be able to look back at them in one place whenever I want.", type: "journaling" },
{ text: "Reviews is probably my most favorite journal. Despite the picture below making it seem like I only eat at [DuClaw](http://duclaw.com/), my boyfriend and I actually do enjoy trying new places to eat and drink, and this journal gives me a place to record what I love and, occasionally, what doesn’t love me back so that I know never to order it again.", type: "journaling" },
{ text: "TIL, “Today I Learned”. It’s the newest journal and came from a recommendation on Lifehacker which happened to feature Day One in the screenshot. I haven’t been noting something I learned each day like the recommendation, but it will be helpful for capturing tidbits of information that would be helpful to retain that don’t necessarily have a place to be stored (e.g. where to send that random support request that only comes in once every 2 years).", type: "journaling" },
{ text: "For the next 100 days, actively look for something positive in your partner every day, and write it down.", type: "journaling" },
{ text: "Create a scrapbook of all the things you and your partner do together during the next 100 days. At the end of the 100 days, give your partner the list you created of positive things you observed about them each day, as well as the scrapbook you created.", type: "journaling" },








{ text: "House - hallway, main bathroom, bedroom, ensuite, spare bedroom, living room, kitchen", type: "braindump" },
{ text: "Does anything need to be fixed?", type: "braindump" },
{ text: "What would you like to be in this room?", type: "braindump" },
{ text: "What do you wish weren't in this room?", type: "braindump" },
{ text: "Do you need to talk to someone else about something related to this room?", type: "braindump" },
{ text: "What’s coming up in the next month?", type: "braindump" },
{ text: "What do you need to do to get ready?", type: "braindump" },
{ text: "What should be on your calendar that isn’t?", type: "braindump" },
{ text: "What would help you make and keep a more accurate calendar?", type: "braindump" },
{ text: "What else occurs to you as you look through your calendar?", type: "braindump" },
{ text: "See if there are other obligations you're \"assuming\" and leaving off the list. Don't assume anything. Write it all down. Third, think through your life and lifestyle.", type: "braindump" },
{ text: "What can I do right now to get closer to my goal of _______ ?", type: "braindump" },
{ text: "What will make me feel the most accomplished?", type: "braindump" },
{ text: "What is the next step?", type: "braindump" },
{ text: "Do I even want to do this task or do I only have it written down because I think I should", type: "braindump" },
{ text: "What do you want to accomplish?", type: "braindump" },
{ text: "What path do you want to travel?", type: "braindump" },
{ text: "What attitudes do you need to change?", type: "braindump" },
{ text: "What habits do you need to break and build?", type: "braindump" },
{ text: "What areas do you want to grow in?", type: "braindump" },
{ text: "What needs to happen in your house and life daily & weekly?", type: "braindump" },
{ text: "What would help you start your days strong?", type: "braindump" },
{ text: "housework", type: "braindump" },
{ text: "jobs", type: "braindump" },
{ text: "hospitality", type: "braindump" },
{ text: "extended family", type: "braindump" },
{ text: "friends", type: "braindump" },
{ text: "accountability", type: "braindump" },
{ text: "habits", type: "braindump" },
{ text: "routines", type: "braindump" },
{ text: "games", type: "braindump" },
{ text: "sports", type: "braindump" },
{ text: "exercise", type: "braindump" },
{ text: "music", type: "braindump" },
{ text: "art", type: "braindump" },
{ text: "hygiene", type: "braindump" },
{ text: "schedules", type: "braindump" },
{ text: "meals", type: "braindump" },
{ text: "menu planning", type: "braindump" },
{ text: "grocery shopping", type: "braindump" },
{ text: "office supplies", type: "braindump" },
{ text: "medical needs", type: "braindump" },
{ text: "precautions", type: "braindump" },
{ text: "concerns", type: "braindump" },
{ text: "yardwork", type: "braindump" },
{ text: "personal grooming", type: "braindump" },
{ text: "library", type: "braindump" },
{ text: "reading", type: "braindump" },
{ text: "storage", type: "braindump" },
{ text: "gift-giving", type: "braindump" },
{ text: "papers", type: "braindump" },
{ text: "magazines", type: "braindump" },
{ text: "junk mail", type: "braindump" },
{ text: "email", type: "braindump" },
{ text: "cleaning supplies", type: "braindump" },
{ text: "projects", type: "braindump" },
{ text: "responsibilities", type: "braindump" },
{ text: "bills", type: "braindump" },
{ text: "budgeting", type: "braindump" },
{ text: "holidays", type: "braindump" },
{ text: "birthdays", type: "braindump" },
{ text: "family traditions", type: "braindump" },
{ text: "vacations", type: "braindump" },
{ text: "clothes &amp; shoes", type: "braindump" },
{ text: "home improvement", type: "braindump" },


{ text: "Treat", type: "drawing"},
{ text: "Armchair", type: "drawing"},
{ text: "Teapot", type: "drawing"},
{ text: "Pastry", type: "drawing"},
{ text: "Breakfast", type: "drawing"},
{ text: "Lunch", type: "drawing"},
{ text: "Dinner", type: "drawing"},
{ text: "Fairy lights", type: "drawing"},
{ text: "Cake", type: "drawing"},
{ text: "Tea", type: "drawing"},
{ text: "Coffee", type: "drawing"},
{ text: "The view from your current position", type: "drawing"},
{ text: "Rain", type: "drawing"},
{ text: "Cold weather", type: "drawing"},
{ text: "Warm weather", type: "drawing"},
{ text: "Plants", type: "drawing"},
{ text: "Candle", type: "drawing"},
{ text: "Guinea pigs", type: "drawing"},
{ text: "Kittens", type: "drawing"},
{ text: "A Cottage", type: "drawing"},
{ text: "Books", type: "drawing"},
{ text: "Home", type: "drawing"},



{ text: "1. What do I need to know?", type: "morning-pages" },
{ text: "2. What do I need to try?", type: "morning-pages" },
{ text: "3. What do I need to accept?", type: "morning-pages" },
{ text: "4. What do I need to do?", type: "morning-pages" },
{ text: "What should I do next?", type: "morning-pages" }

];

export default data;
